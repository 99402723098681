import { useState, useRef, useEffect, useContext } from "react";

import { resObj, paginationVal } from "../../utils/constants";
import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import AutoLogout from "../../utils/useAutoLogout";

import Pagination from '@mui/material/Pagination';

import SideDrawer from "../../components/layout/SideDrawer";
import ErrorModal from "../../components/layout/ErrorModal";
import QueryHeader from "../../components/analytics/QueryHeader";
import CustomTable from "../../components/layout/CustomTable";
import NoData from "../../components/layout/NoData";
import Loader from "../../components/layout/Loader";
import QueryFilterModal from "../../components/analytics/QueryFilterModal";
import SuccessAlert from "../../components/layout/SuccessAlert";

const QueryPage = () => {
  const api = useAxios();
  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Analytics-Querying', 'Analytics-Alchemite Export']);
  }, [permisionsList]);

  const filterData = {
    ionisable_list: [],
    sterol_list: [],
    neutral_list: [],
    peg_list: [],
  };

  const selectedData = {
    ionisable: [],
    sterol: [],
    neutral: [],
    peg: [],
    showAll: false,
  };

  const [apiRes, setApiRes] = useState(resObj);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [filterDataObj, setFilterDataObj] = useState(filterData);
  const [selectedDataObj, setSelectedDataObj] = useState(selectedData);
  const [queryList, setQueryList] = useState([]);
  const pageData = useRef({...paginationVal});

  const tableHeading = [
    'SI No', 'LNP Sample Name', 'Ionisable', 'Sterol', 'Neutral', 'PEG', 
    'Sample Replicate', 'Storage Condition', 'Time Point', 'Diameter', 'PD Index', 'Modality', 
    'EE %', 'Recovery %', 'TE @ 1', 'Cells num % change @ 1', 'Green cells norm @ 1',
    'TE @ 10', 'Cells num % change @ 10', 'Green cells norm @ 10', 'TE @ 30', 
    'Cells num % change @ 30', 'Green cells norm @ 30', 'Quality Check',
  ];

  const clearFilterForm = () => {
    setSelectedDataObj(selectedData);
  };

  const navigateToEdit = () => {
    return;
  };

  const handleDownload = async () => {
    try {
      setApiRes({...apiRes, loading: true});
    
      const response = await api.get('/api/analytics/download/file', {
        params: {
          search_term: pageData.current.searchTerm,
          filter_data: JSON.stringify(selectedDataObj),
        },
        responseType: 'blob'
      });

      if (response.status === 200) {
        const fileDownload = require('js-file-download');
        fileDownload(response.data, 'Query Result.xlsx');
        setApiRes({...apiRes, loading: false});
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Download File',
        loading: false,
      });
    }
  };

  useEffect(() => {
    getQueryResults();
  }, []);

  const handlePaginationClick = (e, pageNumber) => {
    pageData.current.pageNumber = pageNumber;
    getQueryResults();
  };

  const getQueryResults = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/query/result/get', {
        params: {
          page_number: +pageData.current.pageNumber,
          search_term: pageData.current.searchTerm,
          filter_data: JSON.stringify(selectedDataObj),
        }
      });

      if (response.status === 200) {
        setQueryList(response.data.data);
        pageData.current.pageCount = response.data.page_count;
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Query Result Get',
        loading: false,
      });
    }
  };

  const getQueryFilters = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/query/filters/get');

      if (response.status === 200) {
        setFilterDataObj({
          ...filterDataObj,
          ionisable_list: response.data.ionisable_list,
          sterol_list: response.data.sterol_list,
          neutral_list: response.data.neutral_list,
          peg_list: response.data.peg_list,
        });
        setFilterIsOpen(true);
        setApiRes({...apiRes, loading: false});
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Query Filters Get',
        loading: false,
      });
    }
  };

  return(
    <AutoLogout>
      {apiRes.loading && <Loader />}
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <QueryHeader 
          title={'Query'} 
          setFilterIsOpen={getQueryFilters} 
          onDownload={handleDownload}
          pageData={pageData}
          search={getQueryResults}
        />

        <QueryFilterModal 
          search={getQueryResults}
          open={filterIsOpen}
          setOpen={setFilterIsOpen}
          clearFilterForm={clearFilterForm}
          filterData={filterDataObj}
          selectedDataObj={selectedDataObj}
          setSelectedDataObj={setSelectedDataObj}
          pageData={pageData}
          hasSpecialistPermission={permisionsList.includes('Quality-Quality Control Specialist')}
        />

        <CustomTable
          heading={tableHeading} 
          rows={queryList} 
          pageData={pageData}
          style={{ marginTop: '4px' }}
          navigateToEdit={navigateToEdit}
          setDeleteModalOpen={navigateToEdit}
          setId={navigateToEdit}
          idKey={'id'}
          isDeleteBtnVisible={false}
          showToolTip={true}
          setApiRes={setApiRes}
        />

        {queryList.length === 0 && <NoData />}

        {(queryList.length !== 0 && pageData.current.pageCount !== 1) && <Pagination 
                            style={{marginTop: '10px', float: 'right'}} 
                            count={pageData.current.pageCount} 
                            onChange={handlePaginationClick}/>}

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default QueryPage;