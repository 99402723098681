import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import Button from '@mui/material/Button';

import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';

import SubTable from '../layout/SubTable';

const QualityDetailForm = ({ 
  row, tableHeading, handleGraphClick, handleVerifyClick, 
  hasSpecialistPermission 
}) => {
  const titleColor = (row.is_specialist_verify_req) ? 
    'error' : 'text.secondary';

  const qualityPassed = row.is_quality_passed ? 
    'Passed': row.is_quality_passed === false ? 'Failed' : '';

  return(
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Typography 
            component="h5" 
            variant="h5" 
            color={titleColor}
            sx={{ margin: 1.5 }}
            style={{
              textAlign: 'center'
            }}
          >
            <strong>{row.metric_name}</strong>
          </Typography>

          <Tooltip title="View Graph" arrow>
            <IconButton
              size="large"
              color="inherit"
              onClick={e => handleGraphClick(row.metric_name, row.control_list)}
            >
              <ScatterPlotIcon />
            </IconButton>
          </Tooltip>
          
          {
            (row.is_specialist_verify_req && hasSpecialistPermission) &&
              <Button
                variant='outlined'
                color='error'
                onClick={e => handleVerifyClick(row.plate_quality_metric_id, row.metric_name)}
              >
                Verify!
              </Button>
          }
          
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Typography color={'text.secondary'} >
          <strong>Message: {row.message}</strong>
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography color={'text.secondary'} >
          <strong>Storage Condition: {row.storage_condition}</strong>
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography 
          color={
            qualityPassed === 'Passed' ? 
              'success.main' : qualityPassed === 'Failed' ? 
                'error' : 'text.secondary'
          }
        >
          <strong>
            Quality Check: {qualityPassed}
          </strong>
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography color={'text.secondary'} >
          <strong>
            Specialist Verification: {row.is_specialist_verify_req ? 'Required': 'Not Required'}
          </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <SubTable 
          excludeKeys={['lnp_parameter_id', 'lnp_sample_id', 'lnp_sample_name']} 
          heading={tableHeading} 
          rows={row.control_list} 
        />
      </Grid>
    </Grid>
  );
};

QualityDetailForm.propTypes = {
  row: PropTypes.object.isRequired,
  tableHeading: PropTypes.array.isRequired,
  handleGraphClick: PropTypes.func.isRequired,
  handleVerifyClick: PropTypes.func.isRequired,
  hasSpecialistPermission: PropTypes.bool.isRequired,
};

export default QualityDetailForm;