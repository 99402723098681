import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { resObj } from "../../utils/constants";
import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import AutoLogout from "../../utils/useAutoLogout";

import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";

import SideDrawer from "../../components/layout/SideDrawer";
import LNPSampleHeader from "../../components/analytics/LNPSampleHeader";
import Loader from "../../components/layout/Loader";
import ErrorModal from "../../components/layout/ErrorModal";
import FormulationForm from "../../components/analytics/FormulationForm";
import SubTable from "../../components/layout/SubTable";
import CommentModalForm from "../../components/analytics/CommentModalForm";
import SuccessAlert from "../../components/layout/SuccessAlert";
import CommentCard from "../../components/analytics/CommentCard";
import DeleteModal from "../../components/layout/DeleteModal";
import AnalysisTab from "../../components/analytics/AnalysisTab";

const LNPSampleDetailPage = () => {
  const api = useAxios();
  const navigate = useNavigate();

  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Analytics-View Data']);
  }, [permisionsList]);

  const initialValues = {
    lnpSampleName: '',
    ionisableName: '',
    sterolName: '',
    neutralName: '',
    pegName: '',
    ionisableMolarRatio: '',
    sterolMolarRatio: '',
    neutralMolarRatio: '',
    pegMolarRatio: '',
    lipidTotalMolarConcentration: '',
    npRatio: '',
    parameterList: [],
  };
  const [apiRes, setApiRes] = useState(resObj);
  const [values, setValues] = useState(initialValues);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [comment, setComment] = useState('');
  const [commentList, setCommentList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalObj, setDeleteModalObj] = useState({
    title: '', message: '', type: ''
  });
  const [commentId, setCommentId] = useState('');
  const [tabValue, setTabValue] = useState('CPI & UoS');
  const [graphHeading, setGraphHeading] = useState([]);
  
  const docUrIArr = window.location.href.split('/');
  const lnpSampleId = docUrIArr[docUrIArr.length - 1];
  const backURL = '/formulations';
  const lnpDeleteObj = {
    title: 'LNP Sample Delete',
    message:  `Are you sure you want to delete? 
    (All related informations, LNP Paramters, PSD Summary, PSD Graph, 
    Ribogreen, Transfection and Comments will be lost)`,
    type: 'LNP Sample',
  };
  const commentDeleteObj = {
    title: 'Comment Delete',
    message:  `Are you sure you want to delete (Comment will be lost)?`,
    type: 'Comment',
  };

  const parameterHeading = [
    'Sample Replicate', 'Well ID', 'Labware Type', 'Tip Type', 'AQ Labware', 'AQ Well',
    'Mixing Cycles', 'Encapsulation Speed', 'Dilution Speed', 'LNP Plate Barcode'
  ];

  // CPI & UoS Tab Analysis Table Headings 
  const ribogreenHeading = [
    'Sample Replicate', 'RG Plate', 'Well', 'Buffer', 'Raw FI', 'Curve m value', 
    'Curve c value', 'Curve r2 value', 'Sample Conc (μg/mL)', 'Expected Sample Conc (μg/mL)', 
    'Recovery (%)', 'EE (%)', 'Storage Condition', 'Time Point', 'Status',
  ];
  const dlsSummaryHeading = [
    'Sample Replicate', 'Item', 'Data Quality', 'Intensity (Cnt/s)', 'Normalized Intensity (Cnt/s)', 
    'Well', 'Radius (nm)', 'Amplitude', 'Diffusion Coefficient (cm/s)', 'Diameter (nm)', 
    'Polydispersity (nm)', '%PD', 'PD Index', 'Storage Condition', 'Time Point', 'Status',
  ];

  //MDC Tab Analysis Table Headings
  const transfectionHeading = [
    'Sample replicate', 'Barcode', 'Row', 'Column', 'Cells number objects', 'Transfection efficiency', 'Number analyzed fields', 
    'Condition', 'Concentration', 'Field correction', 'Avg cells number objects pos neg ctrls', 'Cells number percentage change', 
    'Green cells intensity mean normalised', 'Well', 'Plate ng', 'Storage Conditions', 'Time Point', 'Status',
  ];

  // UoLTab Analysis Table Headings
  const dlsHeading = [
    'DLS ID', 'LNP Identifier', 'Make/Model', 'Scattering Angle', 'Wavelength', 'Material Refractive Index', 
    'Dispersant', 'Dispersant Refractive Index', 'Dispersant Viscosity (25°C)', 'Cell type', 'Date Measured', 
    'Site measured', 'Date Received', 'Freeze thaw cycles', 'Comments', 'Status',
  ];
  const dlsRunHeading = [
    'DLS ID', 'Run', 'Size', 'PDI', 'Modality', 'Status',
  ];
  const lalHeading = [
    'LNP Identifier', 'Date Measured', 'Date Received', 'Freeze-thaw cycles', 'Dilution factor', 
    'Test Concentration (ng/mL)', 'Spike Recovery (%)', 'Pass/Fail', 'Status',
  ];
  const cytotoxicityHeading = [
    'LNP Identifier', 'Date Measured', 'Date Received', 'Freeze-thaw cycles', 
    'Cell Line', 'WST1 CC20 (ng/mL)', 'LDH CC20 (ng/mL)', 'Status',
  ];
  const ku812Heading = [
    'LNP Identifier', 'Date Received', 'Date Tested', 'Freeze-thaw cycles', 'Test Concentration (ug/mL)', 
    'CD63 - Mean % of -Ve Control', 'CD63 - %CV', 'CD203c - Mean % of -Ve Control', 'CD203c - %CV', 
    'CD164 - Mean % of -Ve Control', 'CD164 - %CV', 'Status',
  ];
  const coagulationHeading = [
    'LNP Identifier', 'Date Received', 'Date Tested', 'Material Freeze-thaw cycles', 
    'Plasma Type','Test Concentration (ug/mL)', 'Comments', 'Status',
  ];
  const complementHeading = [
    'LNP Identifier', 'Date Received', 'Material Freeze-thaw cycles', 'Analyte','Test Concentration (ug/mL)', 'Status', 
    'Date Tested 1', '% of negative control (Untreated) - Replicate 1', '% of negative control (Untreated) - Replicate 2',
    'Date Tested 2', '% of negative control (Untreated) - Replicate 3', '% of negative control (Untreated) - Replicate 4',
  ];
  const coagulationMetricsHeading = [
    'Untreated PT Replicate', 'Untreated TT Replicate', 'Untreated aPTT Replicate', 
    'Treated PT Replicate', 'Treated TT Replicate', 'Treated aPTT Replicate',
  ]

  const replicateArray = (arr, replicate) => {
    return Array.from(
      { length: replicate }, (_, i) => arr.map(item => `${item} ${(i + 1)}`)
    ).flat();
  }

  const cpiData = [
    {name: 'Ribogreen', data: [], heading: ribogreenHeading},
    {name: 'PSD Summary', data: [], heading: dlsSummaryHeading},
    {name: 'PSD Graph', data: [], heading: graphHeading},
  ];
  const [cpiTabData, setCpiTabData] = useState(cpiData);

  const mdcData = [
    {name: 'Transfection', data: [], heading: transfectionHeading},
  ];
  const [mdcTabData, setMdcTabData] = useState(mdcData);
  
  const uolData = [
    {name: 'DLS', data: [], heading: dlsHeading},
    {name: 'DLS Run', data: [], heading: dlsRunHeading},
    {name: 'LAL', data: [], heading: lalHeading},
    {name: 'Cytotoxicity', data: [], heading: cytotoxicityHeading},
    {name: 'KU812', data: [], heading: ku812Heading},
    {
      name: 'Complement', 
      data: [], 
      heading: complementHeading, 
      excludeKeys: ['complement_id', 'replicate', 'date_tested_2', 'date_tested_4'],
    },
    {
      name: 'Coagulation', 
      data: [], 
      heading: coagulationHeading, 
      excludeKeys: ['coagulation_id', 'replicate']
    },
  ];
  const [uolTabData, setUolTabData] = useState(uolData);

  useEffect(() => {
    getLNPSampleDetails();
  }, []);

  const getLNPSampleDetails = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/lnp/samples/detail/get', {
        params: { 
          lnp_sample_id: lnpSampleId,
        }
      });
      
      if (response.status === 200) {
        const data = response.data;
        setValues({
          ...values,
          lnpSampleName: data.lnp_sample.lnp_sample_name,
          ionisableName: data.lnp_sample.ionisable_name,
          sterolName: data.lnp_sample.sterol_name,
          neutralName: data.lnp_sample.neutral_name,
          pegName: data.lnp_sample.peg_name,
          ionisableMolarRatio: data.lnp_sample.ionisable_molar_ratio,
          sterolMolarRatio: data.lnp_sample.sterol_molar_ratio,
          neutralMolarRatio: data.lnp_sample.neutral_molar_ratio,
          pegMolarRatio: data.lnp_sample.peg_molar_ratio,
          lipidTotalMolarConcentration: data.lnp_sample.lipid_total_molar_concentration,
          npRatio: data.lnp_sample.np_ratio,
          parameterList: data.lnp_parameter,
        });

        getComments();
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'LNP Sample Details Get',
        loading: false,
      });
    }
  };

  const addComment = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const formData = new FormData();
      formData.append("comment", comment);
      formData.append("lnp_sample_id", lnpSampleId);
      const response = await api.post('/api/user/add/lnp/sample/comment', formData);

      if (response.status === 200) {
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
        setComment('');
        getComments();
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Add Comment',
        loading: false,
      });
    }
  };

  const getComments = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/user/lnp/sample/comments/get', {
        params: { 
          lnp_sample_id: lnpSampleId,
        }
      });
      
      if (response.status === 200) {
        setCommentList(response.data);
        setApiRes({...apiRes, loading: false});
      }
    } catch (err){
      if (err.message === 'Request failed with status code 403') {
        setApiRes({...apiRes, loading: false});
      } else {
        setApiRes({
          ...apiRes,
          axiosError: true,
          errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
          errHeading: 'Comments Get',
          loading: false,
        });
      }
    }
  };

  const deleteLNPSample = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.delete(`/api/analytics/lnp/sample/delete/${lnpSampleId}`);

      if (response.status === 200) {
        setDeleteModalOpen(false);
        navigate('/formulations');
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: 'Sample deleted successfully',
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Sample Delete',
        loading: false,
      });
    }
  };

  const handleLnpSampleDelete = () => {
    setDeleteModalObj(lnpDeleteObj);
    setDeleteModalOpen(true);
  };

  const handleDeleteComment = commentId => { 
    setCommentId(commentId); 
    setDeleteModalObj(commentDeleteObj);
    setDeleteModalOpen(true);
  };

  const deleteComment = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      setDeleteModalOpen(false);
      const response = await api.delete(`/api/user/lnp/sample/comment/delete/${commentId}`);

      if (response.status === 200) {
        getComments();
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: 'Comment deleted successfully',
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Comment Delete',
        loading: false,
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    let url = '';
    if (tabValue === 'CPI & UoS') {
      url = '/api/analytics/cpiuos/analysis/result/get';
    } else if (tabValue === 'MDC') {
      url = '/api/partner/mdc/analytics/analysis/result/get';
    } else {
      url = '/api/partner/uol/analytics/analysis/result/get';
    }
    if (url !== '') getAnalysisData(url);
  }, [tabValue]);

  const getAnalysisData = async (url) => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get(url, {
        params: { 
          lnp_sample_id: lnpSampleId,
        }
      });
      
      if (response.status === 200) {
        subTableDataUpdate(response.data);
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'LNP Sample Details Get',
        loading: false,
      });
    }
  };

  const subTableDataUpdate = (data) => {
    if (tabValue === 'CPI & UoS') {
      let cpiCopyData = [...cpiTabData];
      cpiCopyData = cpiCopyData.map((obj) =>
        obj.name === "Ribogreen" ? {...obj, data: data.ribogreen} : obj
      );
      cpiCopyData = cpiCopyData.map((obj) =>
        obj.name === "PSD Summary" ? {...obj, data: data.dls_summary} : obj
      );
      cpiCopyData = cpiCopyData.map((obj) =>
        obj.name === "PSD Graph" ? {...obj, data: data.graph_data_list} : obj
      );
      setGraphHeading(data.graph_title_list);
      setCpiTabData(cpiCopyData);
    }
    
    if (tabValue === 'MDC') {
      let mdcCopyData = [...mdcTabData];
      mdcCopyData = mdcCopyData.map((obj) =>
        obj.name === "Transfection" ? {...obj, data: data.transfection} : obj
      );
      setMdcTabData(mdcCopyData);
    }

    if (tabValue === 'UoL') {
      let uolCopyData = [...uolTabData];
      uolCopyData = uolCopyData.map((obj) =>
        obj.name === "DLS" ? {...obj, data: data.dls} : obj
      );
      uolCopyData = uolCopyData.map((obj) =>
        obj.name === "DLS Run" ? {...obj, data: data.dls_run_metrics} : obj
      );
      uolCopyData = uolCopyData.map((obj) =>
        obj.name === "LAL" ? {...obj, data: data.lal} : obj
      );
      uolCopyData = uolCopyData.map((obj) =>
        obj.name === "Cytotoxicity" ? {...obj, data: data.cytotoxicity} : obj
      );
      uolCopyData = uolCopyData.map((obj) =>
        obj.name === "KU812" ? {...obj, data: data.ku812} : obj
      );
      uolCopyData = uolCopyData.map((obj) =>
        obj.name === "Complement" ? {...obj, data: data.complement} : obj
      );

      const maxReplicate = Math.max(...data.coagulation.map(item => item.replicate));
      const metricsHeading = replicateArray(coagulationMetricsHeading, maxReplicate);           
      uolCopyData = uolCopyData.map((obj) =>
        obj.name === "Coagulation" ? 
          {
            ...obj, 
            data: data.coagulation, 
            heading: coagulationHeading.concat(metricsHeading)
          } 
          : obj
      );
      setUolTabData(uolCopyData);
    }
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <LNPSampleHeader 
          title={values.lnpSampleName} 
          backURL={backURL} 
          setShowCommentModal={setShowCommentModal} 
          handleLnpSampleDelete={handleLnpSampleDelete} 
          isDeleteBtnVisible={permisionsList.includes('Analytics-Manage Data')}
          isCommentBtnVisible={permisionsList.includes('Analytics-Manage Comment')}
        />

        <FormulationForm values={values} />

        {commentList.length > 0 && 
          <CommentCard 
            commentList={commentList} 
            handleDeleteComment={handleDeleteComment} 
          />
        }

        { values.parameterList.length > 0 &&
          <>
            <Typography 
              component="h5" 
              variant="h5" 
              color={'text.secondary'}
              sx={{ margin: 1.5 }}
            >
              <strong>LNP Parameters ({values.parameterList.length})</strong>
            </Typography>
            <SubTable heading={parameterHeading} rows={values.parameterList} />
          </>
        }

        <Box sx={{ marginTop: 1.5, padding: 1 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab label='CPI & UoS' value='CPI & UoS' />
            <Tab label='MDC' value='MDC' />
            <Tab label='UoL' value='UoL' />
          </Tabs>
        </Box>

        {
          tabValue === 'CPI & UoS' &&
          <>
            {cpiTabData.map((data, index) => (<AnalysisTab key={index} data={data} />))}
          </>
        }

        {
          tabValue === 'MDC' &&
          <>
            {mdcTabData.map((data, index) => (<AnalysisTab key={index} data={data} />))}
          </>
        }

        {
          tabValue === 'UoL' &&
          <>
            {uolTabData.map((data, index) => (<AnalysisTab key={index} data={data} />))}
          </>
        }

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />

        <CommentModalForm 
          show={showCommentModal} 
          setShow={setShowCommentModal} 
          comment={comment} 
          setComment={setComment} 
          addComment={addComment} 
        />

        <DeleteModal
          title={deleteModalObj.title} 
          message={deleteModalObj.message} 
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDelete={
            deleteModalObj.type === 'LNP Sample' ? 
              deleteLNPSample : deleteComment
          }
        />
      </div>
    </AutoLogout>
  );
};

export default LNPSampleDetailPage;