import PropTypes from 'prop-types';

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import SubTable from "../layout/SubTable";

const AnalysisTab = ({ data }) => {
  return(
    <>
      {data['data'].length > 0 &&
        <Box sx={{ marginTop: 3 }}>
          <Typography 
            component="h5" 
            variant="h5" 
            color={'text.secondary'}
            sx={{ margin: 1.5 }}
          >
            <strong>{data['name']} ({data['data'].length})</strong>
          </Typography>
          
          <SubTable 
            heading={data['heading']} 
            rows={data['data']} 
            excludeKeys={data['excludeKeys']} 
          />
        </Box>
      }
    </>
  );
};

AnalysisTab.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AnalysisTab;