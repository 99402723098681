import { useState, useEffect, useRef, useContext } from "react";

import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import { resObj, paginationVal } from "../../utils/constants";
import { useForm } from "../../utils/useForm";

import Pagination from "@mui/material/Pagination";

import Loader from "../../components/layout/Loader";
import SideDrawer from "../../components/layout/SideDrawer";
import NoData from "../../components/layout/NoData";
import ErrorModal from "../../components/layout/ErrorModal";
import CustomTable from "../../components/layout/CustomTable";
import SuccessAlert from "../../components/layout/SuccessAlert";
import ListHeader from "../../components/layout/ListHeader";
import TableForm from "../../components/analytics/TableForm";
import BackDrop from "../../components/layout/BackDrop";

const TableListPage = () => {
  const api = useAxios();
  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Admin-Manage Table Metadata']);
  }, [permisionsList]);

  const initialValues = {
    metadataId: '',
    table: '',
    fieldName: '',
    csvColumn: '',
    dataType: '',
    description: '',
  };

  const [apiRes, setApiRes] = useState(resObj);
  const {values, setValues, handleInputChange} = useForm(initialValues);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tableFormWidth, setTableFormWidth] = useState(0);
  const [fieldList, setFieldList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const tableHeading = ['SI No', 'Table', 'Column', 'Type', 'CSV Column', 'Description'];
  const pageData = useRef(paginationVal);

  useEffect(() => {
    getFieldsList();
  }, []);

  const getFieldsList = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/table/metadata/get', {
        params: {
          page_number: +pageData.current.pageNumber,
          search_term: pageData.current.searchTerm,
        }
      });

      if (response.status === 200) {
        setFieldList(response.data.data);
        pageData.current.pageCount = response.data.page_count;
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Query Result Get',
        loading: false,
      });
    }
  };

  const navigateToEdit = metadataId => {   
    const res = fieldList.filter(function (el) {
      return el.id === metadataId
    })[0];
    
    setValues({
      ...values, 
      metadataId: metadataId,
      table: res.table_name,
      fieldName: res.field_name,
      csvColumn: res.csv_column !== null ? res.csv_column : '',
      dataType: res.field_type,
      description: res.description !== null ? res.description : '',
    });
    openTableForm();
  };

  const openTableForm = () => {
    setModalIsOpen(true);
    setTableFormWidth(350);
  };

  const closeTableForm = () => {
    setModalIsOpen(false);
    setTableFormWidth(0);
    setValues({
      ...values,
      metadataId: '',
      table: '',
      fieldName: '',
      csvColumn: '',
      dataType: '',
      description: '',
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormErrors(validate(values));
    setIsSubmit(true);
  };

  const validate = values => {
    const errors = {};

    if (!values.description) {
      errors.description = 'Description is required!';
    } 
    
    if (!values.csvColumn) {
      errors.csvColumn = 'CSV Column is required!';
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      tableMetadataEdit();
    }
  }, [formErrors]);

  const tableMetadataEdit = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.post('/api/analytics/table/metadata/edit', {
        metadata_id: values?.metadataId,
        csv_column: values?.csvColumn,
        description: values?.description,
      });

      if (response.status === 200) {
        getFieldsList();
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Table Metadata Edit',
        loading: false,
      });
    }
    closeTableForm();
  };

  const handlePaginationClick = (e, pageNumber) => {
    pageData.current.pageNumber = pageNumber;
    getFieldsList();
  };

  const navigateToCreate = () => {
    return;
  };

  return (
    <>
      {apiRes.loading && <Loader />}
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <ListHeader 
          title={'Table Metadata'} 
          handleClick={navigateToCreate} 
          search={getFieldsList}
          pageData={pageData}
          hasCreatePermission={false} 
        />

        <CustomTable 
          heading={tableHeading}
          rows={fieldList}
          pageData={pageData}
          navigateToEdit={navigateToEdit}
          style={{ marginTop: '8px' }}
          setDeleteModalOpen={navigateToCreate}
          setId={navigateToCreate}
          idKey={'id'}
          isDeleteBtnVisible={false}
          showToolTip={false}
        />

        {modalIsOpen && <TableForm 
          values={values}
          handleInputChange={handleInputChange}
          tableFormWidth={tableFormWidth}
          closeTableForm={closeTableForm}
          onSubmit={handleSubmit}
          formErrors={formErrors}
          isEditBtnVisible={permisionsList.includes('Admin-Manage Table Metadata')}
        />}

        {tableFormWidth === 350 && <BackDrop />}

        {fieldList.length === 0 && <NoData />}

        {(fieldList.length !== 0 && pageData.current.pageCount !== 1) && <Pagination 
                            style={{marginTop: '10px', float: 'right'}} 
                            count={pageData.current.pageCount} 
                            onChange={handlePaginationClick}/>}

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </>
  );
};

export default TableListPage;