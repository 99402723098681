import{ useContext } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

import AuthContext from '../../context/AuthContext';
import PermissionContext from '../../context/PermissionContext';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ScienceIcon from '@mui/icons-material/Science';
import ErrorIcon from '@mui/icons-material/Error';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import TableChartIcon from '@mui/icons-material/TableChart';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';

import SideDrawerItem from './SideDrawerItem';
import logo from '../../assets/images/logo.png';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const valStyle = {
  fontSize: '32px',
  fontWeight: '600',
};

const linkStyle = { 
  textDecoration: 'none', 
  color: 'rgba(0, 0, 0, 0.87)',
};

const SideDrawer = ({ drawerValue, setDrawerValue }) => {
  const { logoutUser } = useContext(AuthContext);
  const { permisionsList } = useContext(PermissionContext);

  const handleDrawerClick = () => {
    setDrawerValue({
      ...drawerValue,
      isDrawerOpen: !drawerValue.isDrawerOpen,
    });
  };

  const dashbordObj = {
    title: 'Dashboard',
    icon: <DashboardIcon />,
    url: '/',
    permissions: ['Analytics-Dashboard']
  };

  const importObj = {
    title: 'Import Data',
    icon: <ImportExportIcon />,
    url: '/import',
    permissions: ['Analytics-Import Data']
  };

  const formulationsObj = {
    title: 'Formulations',
    icon: <ScienceIcon />,
    url: '/formulations',
    permissions: ['Analytics-View Data', 'Analytics-Manage Data']
  };

  const queryObj = {
    title: 'Query',
    icon: <QueryStatsIcon />,
    url: '/query',
    permissions: ['Analytics-Querying', 'Analytics-Alchemite Export']
  };

  const qualityObj = {
    title: 'Quality Control',
    icon: <WorkspacePremiumIcon />,
    url: '/quality/control',
    permissions: [
      'Quality-Quality Control Specialist', 
      'Quality-Quality Control View Data'
    ]
  };

  const cloudObj = {
    title: 'S3 Navigator',
    icon: <CloudQueueIcon />,
    url: '/s3-navigator',
    permissions: ['Analytics-View files in S3']
  };

  const drawerAnalyticsItems = [
    dashbordObj, importObj, formulationsObj, 
    queryObj, qualityObj, cloudObj
  ];

  const roleObj = {
    title: 'Roles',
    icon: <AccessibilityIcon />,
    url: '/roles',
    permissions: [
      'Admin-Role Create', 'Admin-Manage Role', 'Admin-View Role List'
    ]
  };

  const userObj = {
    title: 'Users',
    icon: <GroupsIcon />,
    url: '/users',
    permissions: [
      'Admin-User Create', 'Admin-Manage User', 'Admin-View User List'
    ]
  };

  const tableObj = {
    title: 'Table Metadata',
    icon: <TableChartIcon />,
    url: '/table-metadata',
    permissions: ['Admin-Manage Table Metadata']
  };

  const logObj = {
    title: 'Logs',
    icon: <ErrorIcon />,
    url: '/logs',
    permissions: ['Admin-View Logs List']
  };

  const tableStatsObj = {
    title: 'Table Statistics',
    icon: <StackedLineChartIcon />,
    url: '/table-statistics',
    permissions: ['Admin-View Table Statistics']
  };

  const drawerAdminItems = [roleObj, userObj, tableStatsObj, logObj];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={drawerValue.isDrawerOpen}>
        <DrawerHeader>
          { drawerValue.isDrawerOpen &&
            <Box 
              sx={{ 
                display: 'flex',
                justifyContent: 'center',
                paddingRight: '20px',
              }}
            >
              <img src={logo} alt='logo' 
                style={{
                  width: '43px',
                  height: '43px',
                  marginRight: '5px'
                }} 
              />
              <Typography 
                variant="h5" 
                component="div" 
                style={valStyle}
                color={'text.secondary'}
              >
                IDDC
              </Typography>
            </Box>
          }
          <IconButton onClick={handleDrawerClick}>
            {drawerValue.isDrawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {
            drawerAnalyticsItems.map((data, index) => (
              data.permissions.some(r => permisionsList.includes(r)) &&
                <Link style={linkStyle} to={data.url} key={index}>
                  <SideDrawerItem 
                    open={drawerValue.isDrawerOpen} 
                    icon={data.icon} 
                    text={data.title} 
                  />
                </Link>
            ))
          }
        </List>
        <Divider />
        <List>
          <Link style={linkStyle} to="/profile">
            <SideDrawerItem open={drawerValue.isDrawerOpen} icon={<AccountCircleIcon />} text={'Profile'} />
          </Link>
        </List>
        <Divider />
        <List>
          {
            drawerAdminItems.map((data, index) => (
              data.permissions.some(r => permisionsList.includes(r)) &&
                <Link style={linkStyle} to={data.url} key={index}>
                  <SideDrawerItem 
                    open={drawerValue.isDrawerOpen} 
                    icon={data.icon} 
                    text={data.title} 
                  />
                </Link>
            ))
          }
        </List>
        {
          [
            'Admin-Role Create', 'Admin-Manage Role', 'Admin-View Role List',
            'Admin-User Create', 'Admin-Manage User', 'Admin-View User List',
            'Admin-View Logs List', 'Admin-Manage Table Metadata',
          ].some(r => permisionsList.includes(r)) && 
            <Divider />
        }
        <List>
          <div onClick={logoutUser}>
            <Link style={linkStyle} to="/login">
              <SideDrawerItem open={drawerValue.isDrawerOpen} icon={<LogoutIcon />} text={'Log Out'} />
            </Link>
          </div>
        </List>
      </Drawer>
    </Box>
  );
};

SideDrawer.propTypes = {
  drawerValue: PropTypes.object.isRequired,
  setDrawerValue: PropTypes.func.isRequired,
};

export default SideDrawer;