import { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import NotificationContext from "../../context/NotificationContext";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import AddCommentIcon from '@mui/icons-material/AddComment';
import DeleteIcon from '@mui/icons-material/Delete';

import styles from '../layout/ListHeader.module.css';

const LNPSampleHeader = ({ 
  title, backURL, setShowCommentModal, handleLnpSampleDelete, 
  isDeleteBtnVisible, isCommentBtnVisible 
}) => {
  const { notificationCount, handleNotificationClick } = useContext(NotificationContext);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(backURL);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background: "white", color: "black", boxShadow: "0px 0px 0px 0px" }}>
        <Toolbar>
          <ArrowBackIcon 
            fontSize='large' 
            color='error' 
            className={styles.backBtn}
            onClick={handleBackClick}
          />
          <Typography component="h4" variant="h4" color={'text.secondary'}><strong>{title}</strong></Typography>
          
          <Box sx={{ marginRight: 0, marginLeft: 'auto' }} >
            {isCommentBtnVisible && 
              <Button 
                variant="outlined"
                sx={{ marginRight: 0.5 }} 
                color="success"
                startIcon={<AddCommentIcon />}
                onClick={() => setShowCommentModal(true)}
              >
                Comment
              </Button>
            }

            {isDeleteBtnVisible && 
              <Button 
                variant="outlined"
                sx={{ marginLeft: 0.5 }} 
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleLnpSampleDelete}
              >
                Delete
              </Button>
            }
          </Box>

          <Box 
            justifyContent="center"
            alignItems="center"
            sx={{
              marginLeft: 'auto',
              marginRight: 0,
            }}
          >
            <Tooltip title="View notifications" arrow>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={handleNotificationClick}
              >
                <Badge badgeContent={notificationCount} color="error">
                  <NotificationsIcon 
                    color="action" 
                    style={{
                      width: 30,
                      height: 30,
                    }}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

LNPSampleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  backURL: PropTypes.string.isRequired,
  setShowCommentModal: PropTypes.func.isRequired,
  handleLnpSampleDelete: PropTypes.func.isRequired,
};

export default LNPSampleHeader;