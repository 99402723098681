import { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import useAxios from "../../utils/useAxios";
import { resObj, paginationVal } from "../../utils/constants";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import AutoLogout from "../../utils/useAutoLogout";

import Pagination from '@mui/material/Pagination';

import SideDrawer from "../../components/layout/SideDrawer";
import ListHeader from "../../components/layout/ListHeader";
import CustomTable from "../../components/layout/CustomTable";
import NoData from "../../components/layout/NoData";
import SuccessAlert from "../../components/layout/SuccessAlert";
import ErrorModal from "../../components/layout/ErrorModal";
import DeleteModal from "../../components/layout/DeleteModal";
import Loader from "../../components/layout/Loader";

const RoleListPage = () => {
  const api = useAxios();
  const navigate = useNavigate();
  
  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  const tableHeading = ['SI No', 'Name', 'Description', 'Permissions'];

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Admin-View Role List']);
  }, [permisionsList]);

  const navigateToRoleCreate = () => {
    navigate('/role/create');
  };

  const navigateToRoleEdit = roleId => {
    navigate(`/role/edit/${roleId}`);
  };

  const [apiRes, setApiRes] = useState(resObj);
  const [rolesList, setRolesList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [roleId, setRoleId] = useState('');

  const pageData = useRef({...paginationVal});

  const deleteModalTitle = 'Role Delete';
  const deleteModalMessage = `Are you sure you want to delete? 
    (All related informations will be lost)`;

  useEffect(() => {
    getRoles();
  }, []);

  const handlePaginationClick = (e, pageNumber) => {
    pageData.current.pageNumber = pageNumber;
    getRoles();
  };

  const getRoles = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/role/list/get', {
        params: { 
          search_term: pageData.current.searchTerm,
          page_number: +pageData.current.pageNumber,
        }
      });
      
      if (response.status === 200) {
        setApiRes({ ...apiRes, loading: false });
        setRolesList(response.data.data);
        pageData.current.pageCount = response.data.page_count;
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Roles Get',
        loading: false,
      });
    }
  };

  const deleteRole = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.delete(`/api/role/delete/${roleId}`);

      if (response.status === 200) {
        getRoles();
        setDeleteModalOpen(false);
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: 'Role deleted successfully',
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Role Delete',
        loading: false,
      });
    }
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <ListHeader 
          title="Roles" 
          handleClick={navigateToRoleCreate} 
          search={getRoles}
          pageData={pageData}
          hasCreatePermission={permisionsList.includes('Admin-Role Create')}
        />

        <CustomTable 
          heading={tableHeading} 
          rows={rolesList} 
          navigateToEdit={navigateToRoleEdit}
          setDeleteModalOpen={setDeleteModalOpen}
          setId={setRoleId}
          pageData={pageData}
          idKey={'id'}
          isDeleteBtnVisible={permisionsList.includes('Admin-Manage Role')}
          showToolTip={false}
        />

        {rolesList.length === 0 && <NoData />}

        {(rolesList.length !== 0 && pageData.current.pageCount !== 1) && <Pagination 
                                    style={{marginTop: '10px', float: 'right'}} 
                                    count={pageData.current.pageCount} 
                                    onChange={handlePaginationClick}/>}

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />

        <DeleteModal
          title={deleteModalTitle} 
          message={deleteModalMessage} 
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDelete={deleteRole}
        />
      </div>
    </AutoLogout>
  );
};

export default RoleListPage;