import PropTypes from 'prop-types';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';

import { Form } from "../../utils/useForm";

import styles from "../user_mngmnt/UserForm.module.css";

const TableForm = ({
  values, handleInputChange, tableFormWidth, closeTableForm,
  onSubmit, formErrors, isEditBtnVisible,
}) => {
  return(
    <div className={styles.filterBox} style={{ width: tableFormWidth }}>
      <Form onSubmit={onSubmit}>
        <Grid container className={styles.filterBoxItemTitle}>
          <Typography 
            component="h5" 
            variant="h5" 
            color={'text.secondary'}
          >
            <strong>
              Table Metadata
            </strong>
          </Typography>
          <CloseIcon className={styles.closebtn} onClick={closeTableForm} />
        </Grid>
        <Grid container className={styles.filterBoxItem}>
          <TextField
            varient="outlined"
            label="Table"
            name='table'
            disabled
            value={values.table}
            style={{backgroundColor: 'white', width: '98%', marginTop: '10px'}}
          />
        </Grid>

        <Grid container className={styles.filterBoxItem}>
          <TextField
            varient="outlined"
            label="Column"
            name='fieldName'
            disabled
            value={values.fieldName}
            style={{backgroundColor: 'white', width: '98%', marginTop: '10px' }}
          />
        </Grid>

        <Grid container className={styles.filterBoxItem}>
          <TextField
            varient="outlined"
            label="Type"
            name='dataType'
            disabled
            value={values.dataType}
            style={{backgroundColor: 'white', width: '98%', marginTop: '10px'}}
          />
        </Grid>

        <Grid container className={styles.filterBoxItem}>
          <TextField
            varient="outlined"
            label="CSV Column"
            name='csvColumn'
            value={values.csvColumn}
            onChange={handleInputChange('csvColumn')}
            style={{backgroundColor: 'white', width: '98%', marginTop: '10px' }}
            helperText={formErrors.csvColumn}
            error={'csvColumn' in formErrors}
          />
        </Grid>

        <Grid container className={styles.filterBoxItem}>
          <TextField
            varient="outlined"
            label="Description"
            name='description'
            multiline
            rows={4}
            value={values.description}
            onChange={handleInputChange('description')}
            style={{backgroundColor: 'white', width: '98%', marginTop: '10px' }}
            helperText={formErrors.description}
            error={'description' in formErrors}
          />
        </Grid>

        <Grid container className={styles.filterBoxItemTitle}>
          { isEditBtnVisible && 
            <Button 
              variant="contained" 
              type="submit" 
              color="success"
            >
              Submit
            </Button>}
        </Grid>
      </Form>
    </div>
  );
};

TableForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  tableFormWidth: PropTypes.number.isRequired,
  closeTableForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  isEditBtnVisible: PropTypes.bool.isRequired,
};

export default TableForm;