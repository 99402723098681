import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '20px',
  paddingLeft: '40px',
  overflow: "scroll",
  display:'block',
  maxHeight:'80%',
};

const QualityFilterModal = ({ 
  qualityControl, open, setOpen, clearFilterForm, unverifiedPlateList,
  selectedPlateList, setSelectedPlateList
}) => {
  const submitForm = e => {
    qualityControl();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = () => {
    clearFilterForm();
  };

  const handleChange = event => {
    const plateId = event.target.value;
    if (event.target.checked) {
      setSelectedPlateList(arr => [...arr, +plateId]);
    } else {
      setSelectedPlateList(arr => arr.filter(val => val !== +plateId));
    }
  }; 

  const handleParentChange = event => {
    if (event.target.checked) {
      setSelectedPlateList(unverifiedPlateList.map(obj => obj.plate_id));
    } else {
      setSelectedPlateList([]);
    }
  };

  return(
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>  
          <Box sx={style} id='filterForm'>            
            <Grid container spacing={1}>
              <Grid container spacing={1} style={{ margin: '30px 20px 10px 25px'}}>
                <Grid container spacing={3}>
                  <Typography id="title" variant="h5" component="h5" color={'text.secondary'}>
                    <strong>Plates</strong>
                  </Typography>
                </Grid>
              </Grid>

              <Typography id="description" variant='caption' color={'text.secondary'}>
                <i>Only selected plate(s) will be included for verification.</i>
              </Typography>

              <Grid 
                container 
                spacing={1} 
                sx={{ 
                  marginTop: '0px', 
                  marginLeft: 0, 
                  border: '1px dotted grey',
                  borderRadius: '5px',
                  padding: 1.5
                }
              }>     
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Plates</FormLabel>
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value={'Select All'}
                        control={
                          <Checkbox 
                            checked={
                              selectedPlateList.length > 0 ? 
                                selectedPlateList.sort().toString() === unverifiedPlateList.map(
                                  obj => obj.plate_id).sort().toString() : false
                              }
                            onChange={handleParentChange}
                            indeterminate={
                              selectedPlateList.length > 0 ? 
                                selectedPlateList.sort().toString() !== unverifiedPlateList.map(
                                  obj => obj.plate_id).sort().toString() : false
                              }
                          />
                        }
                        label={'Select All'}
                        labelPlacement="end"
                      />
                      {
                        unverifiedPlateList.map((data, index) => (
                          <FormControlLabel
                            value={data.plate_id}
                            control={
                              <Checkbox 
                                checked={selectedPlateList.includes(data.plate_id)}
                                onChange={handleChange}
                              />
                            }
                            label={data.plate_name}
                            labelPlacement="end"
                            key={index}
                          />
                        ))
                      }
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={1} style={{ marginTop: '0px'}}>
                <Grid item xs={2}>
                  <Button variant="outlined" color='secondary' onClick={handleClose}>Close</Button> 
                </Grid>

                <Grid item xs={2}>                
                  <Button variant="outlined" color='error' onClick={handleClear}>Clear</Button>
                </Grid>

                <Grid item xs={2}>
                  {
                    selectedPlateList.length > 0 &&
                      <Button 
                        variant="contained" 
                        className='taraPrimaryBtn'
                        onClick={submitForm}
                      >
                        Apply
                      </Button>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

QualityFilterModal.propTypes = {
  qualityControl: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  clearFilterForm: PropTypes.func.isRequired,
  unverifiedPlateList: PropTypes.array.isRequired,
  selectedPlateList: PropTypes.array.isRequired,
  setSelectedPlateList: PropTypes.func.isRequired,
};

export default QualityFilterModal;