import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from "@mui/material/Tooltip";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const InfoModal = ({ showInfo, setShowInfo }) => {
  const handleClose = () => {
    setShowInfo(false);
  };

  const lnpSamplesColumns = [
    'Sample Name', 'Ionisable', 'Ionisable Percent', 'Sterol', 'Sterol Percent', 'Neutral', 
    'Neutral Percent', 'PEG',	'PEG Percent', 'N:P', 'Total Lipid (mol/mL)',
  ];

  const lnpParametersColumns = [
    'Sample Name', 'Well ID', 'Labware Type', 'Tip Pos', 'Tip Type', 'AQ Labware', 'AQ Well', 'Tip Size', 
    'Dilution Plate', 'Mixing Cycles', 'Encapsulation Speed', 'Dilution Speed', 'Sample Replicate', 'LNP Plate Barcode',    
  ];

  const lnpParametersIgniteColumns = [
    'Sample Name', 'Labware Type', 'NanoAssemblr Ignite NxGen Cartridge Type', 'NanoAssemblr Ignite Instrument Type',
    'Syringe brand', 'Syringe Volume (mL)', 'Flow Rate Ratio C', 'Flow Rate Ratio R', 'Total Volume (mL)',
    'Start waste (mL)', 'End waste (mL)', 'Dilution Factor (1:X)', 'Ultracentrifuge consumables type', 'Dialysis consumbale type', 
    'Filter type', 'Final RNA concentration (expected - g/mL)',
  ];

  const ribogreenColumns = [
    'LNP Plate', 'Sample Replicate', 'RG Plate', 'Well', 'Buffer', 'Raw FI', 'Curve m value', 'Curve c value', 
    'Curve r2 value', 'Sample Conc (μg/mL)', 'Expected Sample Conc (μg/mL)', 'Recovery (%)', 'EE (%)',
  ];

  const psdSummaryColumns = [
    'Data Quality', 'Item', 'Series', 'Time Stamp', 'Date', 'Time (s)', 'Measurement', 'Measurement ID', 'Image', 'Bubble Detection', 'Intensity (Cnt/s)', 
    'Normalized Intensity (Cnt/s)', 'Number Acqs', 'Number Unmarked Acqs', 'Number Marked Acqs', '% Acqs Unmarked', 'Acq Time (s)', 'DLS Temp (C)', 'Attenuation Level (%)', 
    'Laser Power (%)', 'Set Temp (C)', 'Ramp Rate (C/min)', 'Well', 'Insert Info', 'Row', 'Col', 'Configuration', 'Radius (nm)', 'Amplitude', 'Diffusion Coefficient (cm/s)', 
    'Diameter (nm)', 'Polydispersity (nm)', '%PD', 'PD Index', 'Mw-R (kDa)', 'Baseline', 'SOS', 'Viscosity from Sample Rh (cP)', 'Lambda (1/s)', 'Sigma (1/s)', 'D10 (nm)', 
    'D50 (nm)', 'D90 (nm)', 'Span (D90 - D10)/D50', 'Range1 Radius (I) (nm)', 'Range1 %Pd (I)', 'Range1 Mw-R (I) (kDa)', 'Range1 %Intensity (I)', 'Range1 %Mass (I)', 
    'Range1 %Number (I)', 'Range1 Diameter (I) (nm)', 'Range1 Diffusion Coefficient (I) (cm/s)', 'Range1 Particle Concentration (I) (1/mL)', 'Range2 Radius (I) (nm)', 
    'Range2 %Pd (I)', 'Range2 Mw-R (I) (kDa)', 'Range2 %Intensity (I)', 'Range2 %Mass (I)', 'Range2 %Number (I)', 'Range2 Diameter (I) (nm)', 'Range2 Diffusion Coefficient (I) (cm/s)', 
    'Range2 Particle Concentration (I) (1/mL)', 'Range3 Radius (I) (nm)', 'Range3 %Pd (I)', 'Range3 Mw-R (I) (kDa)', 'Range3 %Intensity (I)', 'Range3 %Mass (I)', 
    'Range3 %Number (I)', 'Range3 Diameter (I) (nm)', 'Range3 Diffusion Coefficient (I) (cm/s)', 'Range3 Particle Concentration (I) (1/mL)', 'Range4 Radius (I) (nm)', 
    'Range4 %Pd (I)', 'Range4 Mw-R (I) (kDa)', 'Range4 %Intensity (I)', 'Range4 %Mass (I)', 'Range4 %Number (I)', 'Range4 Diameter (I) (nm)', 'Range4 Diffusion Coefficient (I) (cm/s)', 
    'Range4 Particle Concentration (I) (1/mL)', 'Range5 Radius (I) (nm)', 'Range5 %Pd (I)', 'Range5 Mw-R (I) (kDa)', 'Range5 %Intensity (I)', 'Range5 %Mass (I)', 'Range5 %Number (I)', 
    'Range5 Diameter (I) (nm)', 'Range5 Diffusion Coefficient (I) (cm/s)', 'Range5 Particle Concentration (I) (1/mL)', 'RMS Error', 'Number Unfiltered Peaks (I)', 'Number Filtered Peaks (I)', 
    'Pre-correction Internal Standard Rh (I) (nm)', 'Internal Standard Viscosity (I) (cP)', 'Particle Concentration (1/mL)', 'Particle Concentration Calculation', 'Particle Material', 
    'Particle Shape', 'Particle Core Real RI', 'Particle Core Imaginary RI', 'Sample', 'Concentration (mg/mL)', 'Mw-R Model', 'Viscosity Calculation: Use Internal Standard', 'Internal Standard Rh (nm)', 
    'Internal Standard Rh Range Minimum (nm)', 'Internal Standard Rh Range Maximum (nm)', 'Solvent', 'Rfr Idx @ 589nm & 20C', 'Viscosity (cP)', 'Temp Model', 'Barcode', 'Sample Replicate',
  ];

  const plateStorageTrackingColumns = [
    'Storage Plate Barcode', 'Storage Condition', 'Storage Measurement Cycle', 'Date in (ctrl ;)', 
    'Time in (ctrl shft ;)', 'Date out (ctrl ;)', 'Time out (ctrl shft ;)', 'Wells',
  ];

  const psdOutputColumns = [
    'Radius (nm)', 'Item Name(s) in PSD Summary loader sheet',
  ];

  const transfectionColumns = [
    'raw_id', 'barcode', 'row', 'column', 'cells_number_objects', 'transfection_efficiency', 'number_analyzed_fields', 
    'condition', 'concentration', 'field_correction', 'avg_cells_number_objects_pos_neg_ctrls', 
    'cells_number_percentage_change', 'green_cells_intensity_mean_normalised', 'well', 'plate_ng', 'Sample', 'lnp_sample_replicate',
  ];

  const biodistributionInVivo = [
    'LNP identifier', 'LNP labelling', 'Injection route', 'Concentration/mRNA dose [ug]', 'LNP buffer', 'In vivo study number', 
    'Animal Number', 'In vivo Imaging time point [hour]', 'In vivo ROI number', 'In vivo ROI size [cm/sr]', 
    'In vivo DiR Total Radiant Efficiency [p/s] / [W/cm]', 'In vivo DiR Avg Radiant Efficiency [p/s/cm/sr] / [W/cm]', 
    'Background ROI size [cm/sr]', 'Background Total Radiant Efficiency [p/s] / [W/cm]', 'Background Avg Radiant Efficiency [p/s/cm/sr] / [W/cm]', 
    'In vivo DiR Total Radiant Efficiency_background subtracted [p/s] / [W/cm]', 'In vivo DiR Avg Radiant Efficiency_background subtracted [p/s/cm/sr] / [W/cm]', 
    'In vivo DiR fold change relative to control',
  ];

  const biodistributionExVivo = [
    'LNP identifier', 'LNP labelling', 'Injection route', 'Concentration/mRNA dose [ug]', 'LNP buffer', 'In vivo study number', 
    'Animal Number', 'Ex vivo time point [hour]', 'Cardiac perfusion buffer', 'Cardiac perfusion volume', 'Organ name', 
    'Ex vivo ROI number', 'Ex vivo ROI size [cm/sr]', 'Ex vivo DiR Total Radiant Efficiency [p/s] / [W/cm]', 
    'Ex vivo DiR Avg Radiant Efficiency [p/s/cm/sr] / [W/cm]', 'Ex vivo GFP Total Radiant Efficiency [p/s] / [W/cm]', 
    'Ex vivo GFP Avg Radiant Efficiency [p/s/cm/sr] / [W/cm]',
  ];

  const expectedColumnsObj = {
    'LNP Samples': lnpSamplesColumns,
    'LNP Parameters': lnpParametersColumns,
    'LNP Parameters Ignite': lnpParametersIgniteColumns,
    'Plate Storage Tracking': plateStorageTrackingColumns,
    'PSD Summary': psdSummaryColumns,
    'PSD Graph': psdOutputColumns,
    'Ribogreen': ribogreenColumns,
    'Transfection': transfectionColumns,
    'Biodistribution in vivo': biodistributionInVivo,
    'Biodistribution ex vivo': biodistributionExVivo,
  };

  const handleDownload = sheet => {
    const fileDownload = require('js-file-download');
    const columns = expectedColumnsObj[sheet].join(',');
    const blob = new Blob([columns], { type: 'text/csv' });
    fileDownload(blob, `${sheet}.csv`);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showInfo}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Expected Columns
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {
            Object.entries(expectedColumnsObj).map(([key, value], index) => (
              <Box key={`box-${index}`}>
                <Box sx={{
                  display: 'flex'
                }}>
                  <Typography key={`head-${index}`}><strong>{key}</strong></Typography>
                  <Tooltip title="Download loader sheet template" arrow>
                    <IconButton size='small' sx={{ marginTop: -0.4 }} onClick={() => handleDownload(key)}>
                      <DownloadIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </Box>
              
                <Typography key={`list-${index}`} gutterBottom>
                  {value.join(', ')}
                </Typography>
              </Box>
            ))
          }
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

InfoModal.propTypes = {
  showInfo: PropTypes.bool.isRequired,
  setShowInfo: PropTypes.func.isRequired,
};

export default InfoModal;