import { Route, Routes } from 'react-router-dom';

import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './utils/PrivateRoute';
import TwoFactorRoute from './utils/TwoFactorRoute';
import { PermissionProvider } from './context/PermissionContext';
import { NotificationProvider } from './context/NotificationContext';
import { FormulationsTabProvider } from './context/FormulationsTabContext';
import { DrawerProvider } from './context/DrawerContext';
import HealthCheck from './utils/HealthCheck';

// Analytics
import DashboardPage from './pages/analytics/Dashboard';
import ImportDataPage from './pages/analytics/ImportData';
import QueryPage from './pages/analytics/Query';
import S3NavigatorPage from './pages/analytics/S3Navigator';
import FormulationsPage from './pages/analytics/Formulations';
import LNPSampleDetailPage from './pages/analytics/LNPSampleDetail';
import AQControlDetailPage from './pages/analytics/AQControlDetail';
import TransfectionControlDetailPage from './pages/analytics/TransfectionControlDetail';
import EmptyWellsPage from './pages/analytics/EmptyWells';
import QualityControlPage from './pages/qualitycontrol/QualityControl';
import QualityControlDetailPage from './pages/qualitycontrol/QualityControlDetail';
import TableListPage from './pages/analytics/TableList';
import TableStatisticsPage from './pages/analytics/TableStatistics';

// Account
import LoginPage from './pages/account/Login';
import ForgotPasswordPage from './pages/account/ForgotPassword';
import ResetPasswordPage from './pages/account/ResetPassword';
import UserProfilePage from './pages/account/UserProfile';
import EmailVerificationPage from './pages/account/EmailVerification';

//User Management
import RoleListPage from './pages/user_mngmnt/RoleList';
import RoleCreatePage from './pages/user_mngmnt/RoleCreate';
import RoleEditPage from './pages/user_mngmnt/RoleEdit';
import UserListPage from './pages/user_mngmnt/UserList';
import LogListPage from './pages/user_mngmnt/LogList';

//Common
import PageNotFoundPage from './pages/common/PageNotFound';
import NotificationPage from './pages/common/Notification';

const App = () => {
  return (
    <>
      <Routes>
        <Route path='/login' element={<AuthProvider><LoginPage /></AuthProvider>} />
        <Route path='/forgot-password' element={<ForgotPasswordPage />}/>
        <Route path='/reset-password/:code' element={<ResetPasswordPage />}/>
        <Route path='/page-not-found' element={<PageNotFoundPage />}/>
        <Route path='/health' element={<HealthCheck />}/>

        <Route path='/emailverification' element={
          <AuthProvider>
            <TwoFactorRoute Component={EmailVerificationPage} />
          </AuthProvider>} 
        />

        <Route exact path='/' element={ 
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={DashboardPage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/import' element={ 
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={ImportDataPage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/query' element={ 
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={QueryPage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/quality/control' element={ 
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={QualityControlPage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/quality/control/plate/:id' element={ 
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={QualityControlDetailPage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/s3-navigator' element={ 
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={S3NavigatorPage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/formulations' element={ 
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <FormulationsTabProvider>
                    <PrivateRoute Component={FormulationsPage} />
                  </FormulationsTabProvider>
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route path='/lnpsample/detail/:id' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <FormulationsTabProvider>
                    <PrivateRoute Component={LNPSampleDetailPage} />
                  </FormulationsTabProvider>
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route path='/aqcontrol/detail/:id' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <FormulationsTabProvider>
                    <PrivateRoute Component={AQControlDetailPage} />
                  </FormulationsTabProvider>
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route path='/transfectioncontrol/detail/:id' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <FormulationsTabProvider>
                    <PrivateRoute Component={TransfectionControlDetailPage} />
                  </FormulationsTabProvider>
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route path='/empty/wells/detail/:id' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <FormulationsTabProvider>
                    <PrivateRoute Component={EmptyWellsPage} />
                  </FormulationsTabProvider>
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/table-statistics' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={TableStatisticsPage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/profile' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={UserProfilePage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/roles' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={RoleListPage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/role/create' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={RoleCreatePage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route path='/role/edit/:id' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={RoleEditPage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/users' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={UserListPage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route exact path='/logs' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={LogListPage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route path='/notifications' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={NotificationPage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route path='/table-metadata' element={
          <AuthProvider>
            <PermissionProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <PrivateRoute Component={TableListPage} />
                </DrawerProvider>
              </NotificationProvider>
            </PermissionProvider>
          </AuthProvider>}
        />

        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;