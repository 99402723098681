import PropTypes from 'prop-types';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import ScatterChart from '../layout/ScatterChart';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};

const QualityGraphModal = ({ 
  show, setShow, graphValues, setGraphValues
}) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={show}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={show}>
        <Box sx={style}>
          <Box sx={{ marginTop: 1.5 }}>
            <FormControl fullWidth>
              <InputLabel id="quality-control-label">Quality Control</InputLabel>
              <Select
                labelId="quality-control-label"
                id="quality-control-select"
                value={graphValues.qualityControl}
                label="Quality Control"
                onChange={e => setGraphValues({...graphValues, qualityControl: e.target.value})}
              >
                {
                  graphValues.uniqueQualityControlList.map((value, index) => (
                    <MenuItem value={value} key={index}>{value}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              height: '85%'
            }}
          >
            <ScatterChart 
              title={graphValues.metricName} 
              controlLimit={graphValues.controlLimit} 
              graphList={graphValues.graphList} 
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

QualityGraphModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  graphValues: PropTypes.object.isRequired,
  setGraphValues: PropTypes.func.isRequired,
};

export default QualityGraphModal;