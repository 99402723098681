import PropTypes from 'prop-types';

import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

const CommentCard = ({ commentList, handleDeleteComment }) => {
  return(
    <>
      <Typography 
        component="h5" 
        variant="h5" 
        color={'text.secondary'}
        sx={{ margin: 1.5 }}
      >
        <strong>Comments</strong>
      </Typography>

      {
        commentList.map((data, index) => (
          <Card key={index} sx={{ marginTop: 0.5 }}>
            <CardContent>
              <Stack direction="row" spacing={1}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  <i>{data.full_name} commented on {data.created_date}</i>
                </Typography>

                <Tooltip title="Delete Comment" arrow>
                  <IconButton
                    size="small"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    onClick={() => handleDeleteComment(data.id)}
                  >
                    <DeleteForeverOutlinedIcon 
                      fontSize='small' 
                      sx={{ cursor: 'pointer' }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Avatar>{data.full_name[0]}</Avatar>
                <Typography variant="body2">
                  {data.comment}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        ))
      }
    </>
  );
};

CommentCard.propTypes = {
  commentList: PropTypes.array.isRequired,
  handleDeleteComment: PropTypes.func.isRequired,
};

export default CommentCard;