import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useAxios from '../../utils/useAxios';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Tooltip from "@mui/material/Tooltip";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#6c757d',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CustomTable = ({
  heading, rows, navigateToEdit, setDeleteModalOpen, setId,
  pageData, idKey, isDeleteBtnVisible, style, showToolTip, setApiRes, 
}) => {
  const api = useAxios();

  const [tooltipList, setTooltipList] = useState([]);

  const handleClick = id => {
    navigateToEdit(id);
  };

  const handleDeleteClick = id => {
    setId(id);
    setDeleteModalOpen(true);
  };

  const getToolTip = columnName => {
    const data = tooltipList.filter(function (el) {
      return el.csv_column === columnName
    });

    return data.length > 0 ? data[0].description : '';
  };

  useEffect(() => {
    if (showToolTip) getToolTipList();
  }, []);

  const getToolTipList = async () => {
    try {
      setApiRes((prevstate) => ({...prevstate, loading: true}));
      const response = await api.get('/api/analytics/tooltip/get');
      
      if (response.status === 200) {
        setTooltipList(response.data);
        setApiRes((prevstate) => ({ ...prevstate, loading: false }));
      }
    } catch (err){
      setApiRes((prevstate) => ({ ...prevstate, loading: false }));
    }
  };

  return(
    <Box style={style}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {heading.map((item, index) => (
                <Tooltip title={getToolTip(item)} arrow key={index}>
                  <StyledTableCell align="center">{item}</StyledTableCell>
                </Tooltip>
              ))}
              {isDeleteBtnVisible && <StyledTableCell align="center">Action</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow key={index} style={{ cursor: 'pointer' }}>
                <StyledTableCell 
                  align="center" 
                  key={`SI-${index}`} 
                  onClick={() => handleClick(row.id)}
                >
                  {((pageData.current.pageNumber - 1) *10) + (index + 1)}
                </StyledTableCell>
                {Object.keys(row).map((key, i) => (
                  (
                    ( key !== idKey && !key.endsWith('_id')) && 
                    <StyledTableCell 
                      align="center" 
                      key={i} 
                      onClick={() => handleClick(row[idKey])}
                    >
                      {row[key]}
                    </StyledTableCell>
                  )
                ))}
                {
                  isDeleteBtnVisible && 
                  <StyledTableCell 
                    align="center" 
                    key={`Delete-${index}`}
                  >
                    <DeleteOutlinedIcon onClick={() => handleDeleteClick(row[idKey])} />
                  </StyledTableCell>
                }
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

CustomTable.propTypes = {
  heading: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  navigateToEdit: PropTypes.func.isRequired,
  setDeleteModalOpen: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  pageData: PropTypes.object.isRequired,
  idKey: PropTypes.string.isRequired,
  isDeleteBtnVisible: PropTypes.bool.isRequired,
  style: PropTypes.object,
  showToolTip: PropTypes.bool.isRequired,
  setApiRes: PropTypes.func,
};

export default CustomTable;